<template>
  <div class="aboutUs">
    <img src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/1.jpg" alt="header">
    <div class="aboutUs_content">
      <h1>联系我们</h1>
      <div>
        <span>招商热线</span>
        <span>029-88161111</span>
      </div>
      <div>
        <span>400热线</span>
        <span>400-900-7277</span>
      </div>
      <div>
        <span>投诉建议</span>
        <span>400-900-7277</span>
      </div>
      <div>
        <span>办公地址</span>
        <span>陕西省西安市高新区锦业路唐延路十字东南角</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs"
}
</script>

<style scoped lang="scss">
.aboutUs {
  margin-top: 72px;

  img {
    width: 100%;
    //height: 65.9vh;
  }

  .aboutUs_content {
    width: 60%;
    margin: 0 auto;
    padding: 8vh 0 25vh 0;

    h1 {
      padding-bottom: 20px;
    }

    & > div {
      padding: 20px 0;

      :first-child {
        font-size: 20px;
        color: #787E86;
        line-height: 24px;
        padding-right: 5vw;
      }

      :last-child {
        font-size: 20px;
        font-weight: bold;
        color: #373E46;
        line-height: 24px;
      }
    }
  }
}
</style>
